import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

const VideoBF = ({ linkVideo, image, title, text }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div className='bgCountentVideo grid grid-cols-2 gap-4 place-content-center h-48'
            style={{
                backgroundImage:`url("${rpdata?.gallery?.[1]}")`
            }}
        >
            <div className='countentVideoInfo'>
            <h2 className='text-center text-white'>Before & After</h2>
                <iframe width="329" height="586" src="https://www.youtube.com/embed/3gONQ3WkUM0" title="MR PAVING AND CONSTRUCTION CORP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <iframe width="329" height="586" src="https://www.youtube.com/embed/sUAITcyyjWs" title="MR PAVING AND CONSTRUCTION CORP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            </div>
        </div>
    )
}

export default VideoBF


