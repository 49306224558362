import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

const VideoPromocional = ({ linkVideo, image, title, text }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage:`url("${rpdata?.gallery?.[1]}")`
            }}
        >
            <div className='countentVideoInfo'>
                <div className='info'>
                    <h2 className='text-white'>{title}</h2>
                    <p className='text-white'>{text}</p>
                </div>
                <iframe style={{ marginTop:"100px", marginBottom:"-30px" }} width="560" height="330" src="https://www.youtube.com/embed/Y4M5S1BWVUY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default VideoPromocional


