import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
// import Data from "../../api/data.json";
// import {MdConstruction} from 'react-icons/md';
// import {TbPaint} from 'react-icons/tb';
// import {FaHardHat} from 'react-icons/fa';
// import {GrStackOverflow} from 'react-icons/gr';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';


const Navbar = (props) => {
  const {rpdata} = useContext(GlobalDataContext);

  return (
    <div className="navbar-area">
      <div className="navbar-top">
      <div className="navbar-top2">
      <div className="row">
			          <div className="col-md-4">
			            <div className="top-dat">
                    <div className="logo">
                      <Link to="/">
                        <img
                          src={rpdata?.dbPrincipal?.logo}
                          alt="img"
                          className="logo_responsive"
                        />
                      </Link>
                    </div>
			            </div>
			          </div>
					  <div className="col-md-4">
			            <div className="top-dat2">
                  <ul>
                    <li>
                      <p className="text-white">
                        <i className="fa fa-map-marker" />{" "}
                        {rpdata?.dbPrincipal?.location[0].address}
                      </p>
                    </li>
                    {rpdata?.dbPrincipal?.phones.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={`tel:+1${item.phone}`} >
                            <p className="text-white">
                              <i className="fa fa-phone" /> {item.phone}
                            </p>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  </div>
			          </div>
			          <div className="col-md-4">
			            <div className="top-dat3">
                      <ul className="topbar-right text-md-right text-center">
                        <li className="social-area">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                            <a href={`${item.url}`} target="_blank" rel="noopener noreferrer" className="text-white">
                                <i
                                className={`fab fa-${item.icon}`}
                                aria-hidden="true"
                                ></i>
                                
                            </a>
                            );
                        })}
                        </li>
                      </ul>
			            </div>
			          </div>
			        </div>
      </div>
      </div>
      <nav className="navbar navbar-area-2 navbar-area navbar-expand-lg go-top">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              className="menu toggle-btn d-block d-lg-none"
              data-target="#edumint_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                document
                  .querySelector(".navbar-collapse")
                  .classList.toggle("show");
              }}
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="nav-right-part nav-right-part-mobile">
            <Link className="btn btn-base text-white" to="/contact">
              Free Estimate
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="edumint_main_menu">
            <ul className="navbar-nav menu-open">
              <li className="menu-item-has-children current-menu-item">
                <Link to="/">Home</Link>
              </li>
              <li className="menu-item-has-children">
                <Link to="/about">About</Link>
              </li>
              <li className="menu-item-has-children">
                <Link to="/services">Services</Link>
              </li>
              <li className="menu-item-has-children">
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>

        </div>
      </nav>
    </div>
  );
};

export default Navbar;
