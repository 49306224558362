import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const BlockHomeTwo = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="counter-area bg-gray2">
      <div className="container bg-gray3">
        <div
          className="counter-area-inner pd-top-120 pd-bottom-120"
          style={{
            backgroundImage: 'url("' + publicUrl + 'assets/img/other/1.png")',
          }}
        >
          <div className="row">
            <div className="col-lg-8 mb-5 mb-lg-0">
              <div className="section-title mb-0">
                <h6 className="sub-title right-line">
                  {rpdata?.dbSlogan?.[4].slogan}
                </h6>
                <h2 className="title">{rpdata?.dbSlogan?.[0].slogan}</h2>
                <p className="content pb-3">{rpdata?.dbHome?.[1].text}</p>
                <Link
                  className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                  to="/contact"
                >
                  Free Estimate
                </Link>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <ul className="single-list-wrap">
                <li className="single-list-inner style-box-bg">
                  <div className="media">
                    <div className="media-left">
                      <i className="fa fa-check" />
                    </div>
                    <div className="media-body align-self-center">
                      <h5>
                        <span className="counter">100</span>%
                      </h5>
                      <p>Professionalism</p>
                    </div>
                  </div>
                </li>
                <li className="single-list-inner style-box-bg">
                  <div className="media">
                    <div className="media-left">
                      <i className="fa fa-check" />
                    </div>
                    <div className="media-body align-self-center">
                      <h5>
                        <span className="counter">100</span>%
                      </h5>
                      <p>On Time</p>
                    </div>
                  </div>
                </li>
                <li className="single-list-inner style-box-bg">
                  <div className="media">
                    <div className="media-left">
                      <i className="fa fa-check" />
                    </div>
                    <div className="media-body align-self-center">
                      <h5>
                        <span className="counter">100</span>%
                      </h5>
                      <p>Diligence</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockHomeTwo;
